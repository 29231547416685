import { FriendlyError } from '@/util/FriendlyError';
import axios from 'axios';
import IMusicService from './IMusicService';

export class SpotifyService extends IMusicService {
    static SUPABASE_PROVIDER_NAME = 'spotify';
    static SUPABASE_SCOPES = ['user-library-read', 'user-read-private', 'user-read-currently-playing', 'playlist-read-private', 'playlist-modify-public', 'playlist-modify-private'];
    static BASE_URL = 'https://api.spotify.com/v1';
    static DEFAULT_LIMIT = 50;

    static #instance = null;

    constructor() {
        super();
        if (SpotifyService.#instance) {
            throw new Error('SpotifyService instance already created. Use SpotifyService.getInstance()');
        }
    }

    static getInstance() {
        if (!SpotifyService.#instance) {
            SpotifyService.#instance = new SpotifyService();
        }
        return SpotifyService.#instance;
    }

    async init() {
        if (!sessionStorage.spotify_user) {
            const data = await this.getCurrentUsersProfile();
            sessionStorage.spotify_user = JSON.stringify(data);
        }
    }

    isPremium() {
        return JSON.parse(sessionStorage.spotify_user).product === 'premium';
    }

    // WARNING: This method is not allowed to be used by the Spotify Developer Policy for commercial applications,
    // per https://developer.spotify.com/policy#iv-streaming-and-commercial-use &
    // https://developer.spotify.com/compliance-tips#special-considerations-for-streaming. Since we're trying to
    // make money here, don't use this method in production.
    // If you want to use it, you'll need to add 'user-modify-playback-state' to SpotifyService.SUPABASE_SCOPES.
    async playTrack(spotifyTrackId) {
        try {
            const { data, error } = await axios.put(
                `${SpotifyService.BASE_URL}/me/player/play`,
                {
                    uris: [`spotify:track:${spotifyTrackId}`]
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.provider_token}`
                    }
                }
            );
            if (error) throw error;
            return data;
        } catch (error) {
            if (error?.response?.data?.error?.reason === 'NO_ACTIVE_DEVICE') {
                throw new FriendlyError('No active device... start Spotify on a device and play a track first', error);
            } else if (error?.response?.data?.error?.reason === 'PREMIUM_REQUIRED') {
                throw new FriendlyError('Spotify Premium required', error);
            }
            throw error;
        }
    }

    // WARNING: This method is not allowed to be used by the Spotify Developer Policy for commercial applications,
    // per https://developer.spotify.com/policy#iv-streaming-and-commercial-use &
    // https://developer.spotify.com/compliance-tips#special-considerations-for-streaming. Since we're trying to
    // make money here, don't use this method in production.
    // If you want to use it, you'll need to add 'user-modify-playback-state' to SpotifyService.SUPABASE_SCOPES.
    async addTrackToQueue(spotifyTrackId) {
        try {
            const { data, error } = await axios.post(
                `${SpotifyService.BASE_URL}/me/player/queue?uri=spotify:track:${spotifyTrackId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.provider_token}`
                    }
                }
            );
            if (error) throw error;
            return data;
        } catch (error) {
            if (error?.response?.data?.error?.reason == 'NO_ACTIVE_DEVICE') {
                throw new FriendlyError('No active device... start Spotify on a device and play a track first', error);
            } else if (error?.response?.data?.error?.reason === 'PREMIUM_REQUIRED') {
                throw new FriendlyError('Spotify Premium required', error);
            }
            throw error;
        }
    }

    async getCurrentUsersProfile() {
        const { data, error } = await axios.get(`${SpotifyService.BASE_URL}/me`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    }

    async getCurrentlyPlayingTrackData() {
        const { data, error } = await axios.get(`${SpotifyService.BASE_URL}/me/player/currently-playing`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    }

    async getUsersSavedTracks(offset, limit) {
        const url = `${SpotifyService.BASE_URL}/me/tracks`;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                offset: offset || 0,
                limit: limit || SpotifyService.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    }

    async getTracks(trackIds) {
        if (trackIds.length > 50) {
            throw new Error('Spotify only allows 50 tracks to be fetched at a time');
        }
        const { data, error } = await axios.get(`${SpotifyService.BASE_URL}/tracks`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                ids: trackIds?.join(','),
                limit: SpotifyService.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data.tracks;
    }

    async getArtists(artistIds) {
        if (artistIds.length > 50) {
            throw new Error('Spotify only allows 50 artists to be fetched at a time');
        }
        const { data, error } = await axios.get(`${SpotifyService.BASE_URL}/artists`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                ids: artistIds?.join(','),
                limit: SpotifyService.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data.artists;
    }

    async getPlaylists(next) {
        const url = next || `${SpotifyService.BASE_URL}/me/playlists`;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                limit: SpotifyService.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    }

    async getPlaylistTracks(playlist, offset, limit) {
        const url = playlist.tracks.href;
        const { data, error } = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            },
            params: {
                offset: offset || 0,
                limit: limit || SpotifyService.DEFAULT_LIMIT
            }
        });
        if (error) throw error;
        return data;
    }

    async addPlaylist(playlistName) {
        const spotifyUserId = JSON.parse(sessionStorage.session).user.user_metadata.provider_id;
        if (!spotifyUserId) return;
        const { data, error } = await axios.post(
            `${SpotifyService.BASE_URL}/users/${spotifyUserId}/playlists`,
            {
                name: playlistName
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.provider_token}`
                }
            }
        );
        if (error) throw error;
        return data;
    }

    async addPlaylistTracks(playlist, tracks) {
        if (tracks.length > 50) {
            throw new Error('Spotify only allows 50 tracks to be added at a time');
        }
        const { data, error } = await axios.post(
            `${SpotifyService.BASE_URL}/playlists/${playlist.id}/tracks`,
            {
                uris: tracks.map((track) => track.uri)
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.provider_token}`
                }
            }
        );
        if (error) throw error;
        return data;
    }

    async deletePlaylist(playlistId) {
        const { data, error } = await axios.delete(`${SpotifyService.BASE_URL}/playlists/${playlistId}/followers`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.provider_token}`
            }
        });
        if (error) throw error;
        return data;
    }

    async refreshToken() {
        const { data, error } = await axios.post(
            'https://accounts.spotify.com/api/token',
            {
                grant_type: 'refresh_token',
                refresh_token: sessionStorage.provider_refresh_token,
                client_id: import.meta.env.VITE_SPOTIFY_CLIENT_ID
                // TODO: This only works if we supply a client_secret... THAT CAN'T BE RIGHT
                // Update: Supabase OAuth doesn't initiate the PKCE flow correctly, so it requires a client_secret for now
                // See https://github.com/supabase/auth/issues/1450#issuecomment-2287718083
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );

        if (error) throw error;
        if (data.access_token) {
            sessionStorage.provider_token = data.access_token;
        }
        if (data.refresh_token) {
            sessionStorage.provider_refresh_token = data.provider_refresh_token;
        }
        return data;
    }
}
