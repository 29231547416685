import { MusicServiceFactory } from '@/service/MusicServiceFactory';
import { useTagStore } from '@/stores/tags';
import { useTrackTagStore } from '@/stores/trackTags';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const musicService = MusicServiceFactory.getMusicServiceInstance();

export const useTrackStore = defineStore('tracks', () => {
    const tagStore = useTagStore();
    const trackTagStore = useTrackTagStore();

    const tracks = ref([]);
    const currentlyPlayingTrackData = ref({});
    const isLoading = ref(false);

    async function init() {
        await this.getCurrentlyPlayingTrackData();
    }

    async function getCurrentlyPlayingTrackData() {
        isLoading.value = true;
        const trackData = await musicService.getCurrentlyPlayingTrackData();
        currentlyPlayingTrackData.value = trackData;
        isLoading.value = false;
        return currentlyPlayingTrackData.value;
    }

    async function getUsersSavedTracksData(offset, limit) {
        isLoading.value = true;
        await tagStore.checkInit();
        await trackTagStore.checkInit();
        const tracksData = await musicService.getUsersSavedTracks(offset, limit);
        tracksData.items
            .map((item) => item.track)
            .map((track) => {
                const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.provider_id === track?.id)?.tag_ids;
                track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
                track.tagNames = track.tags.map((tag) => tag.name).join(', ');
                track.artistNames = track.artists.map((artist) => artist.name).join(', ');
            });
        isLoading.value = false;
        return tracksData;
    }

    async function getPlaylistTracksData(playlist, offset, limit) {
        isLoading.value = true;
        await tagStore.checkInit();
        await trackTagStore.checkInit();
        const playlistTracksData = await musicService.getPlaylistTracks(playlist, offset, limit);
        playlistTracksData.items
            .map((item) => item.track)
            .map((track) => {
                const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.provider_id === track?.id)?.tag_ids;
                track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
                track.tagNames = track.tags.map((tag) => tag.name).join(', ');
                track.artistNames = track.artists.map((artist) => artist.name).join(', ');
            });
        isLoading.value = false;
        return playlistTracksData;
    }

    async function getTracks(trackIds) {
        if (!Array.isArray(trackIds)) {
            trackIds = [trackIds];
        }
        if (trackIds.length === 0) {
            return [];
        }
        isLoading.value = true;
        await tagStore.checkInit();
        await trackTagStore.checkInit();
        const tracksData = await musicService.getTracks(trackIds);
        tracksData.map((track) => {
            const tagIds = trackTagStore.trackTags.find((trackTag) => trackTag.provider_id === track?.id)?.tag_ids;
            track.tags = tagStore.tags.filter((tag) => tagIds?.includes(tag.id));
            track.tagNames = track.tags.map((tag) => tag.name).join(', ');
            track.artistNames = track.artists.map((artist) => artist.name).join(', ');
        });
        isLoading.value = false;
        return tracksData;
    }

    return { tracks, currentlyPlayingTrackData, isLoading, init, getCurrentlyPlayingTrackData, getUsersSavedTracksData, getPlaylistTracksData, getTracks };
});
