import { SupabaseService } from '@/service/SupabaseService';
import { useTrackTagStore } from '@/stores/trackTags';
import { FriendlyError } from '@/util/FriendlyError';
import { useTagSorter } from '@/util/tagSorter';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const tagSorter = useTagSorter();

export const useTagStore = defineStore('tags', () => {
    const BULK_TAG_TYPES = {
        MANUAL: 'manual',
        AUTO: 'auto'
    };

    const BULK_TAG_ACTIONS = {
        ADD: 'add',
        REMOVE: 'remove'
    };

    const AUTO_TAG_TYPES = {
        GENRE: 'genre',
        DECADE: 'decade',
        POPULARITY: 'popularity',
        EXPLICITNESS: 'explicitness'
    };

    const trackTagStore = useTrackTagStore();

    const tags = ref([]);

    const sortedTags = computed(() => tagSorter.sortTags(tags.value));

    const getParentTagOptions = computed(() => (candidate) => {
        const options = tags.value.filter((tag) => {
            const isSelf = tag.id === candidate.id;
            const hasParent = tag.parent_id !== undefined && tag.parent_id !== null && tag.parent_id >= 0;
            return !isSelf && !hasParent;
        });
        return tagSorter.sortTags(options);
    });

    async function init() {
        await SupabaseService.getTags().then((tagsData) => (tags.value = tagsData));
        tags.value.forEach((tag) => {
            // We rely on parent being undefined, so don't just skip if parent_id is null
            tag.parent = tag.parent_id === undefined || tag.parent_id === null ? undefined : tags.value.find((parent) => Number(parent.id) === Number(tag.parent_id));
            tag.children = computed(() => tags.value.filter((t) => t.parent_id === tag.id));
        });
    }

    async function checkInit() {
        if (!tags.value.length) {
            await init();
        }
    }

    async function upsertTag(newTag) {
        if (newTag.name.length === 0 || newTag.name.length > 150) {
            throw new FriendlyError('Tag name must be between 1 and 150 characters');
        }
        const isNew = !newTag.id;
        const data = await SupabaseService.upsertTag(newTag.id, newTag.name, newTag.parent_id);
        if (data) {
            newTag = data[0];
            newTag.children = computed(() => tags.value.filter((t) => t.parent_id === newTag.id));
            if (isNew) {
                tags.value.push(newTag);
            } else {
                const index = tags.value.findIndex((tag) => tag.id === newTag.id);
                tags.value[index] = newTag;
            }
            return data;
        }
    }

    async function insertTags(newTagNames, parentTag) {
        if (!Array.isArray(newTagNames)) newTagNames = [newTagNames];
        const data = await SupabaseService.insertTags(newTagNames, parentTag.id);
        if (data) {
            data.forEach((newTag) => {
                newTag.parent = parentTag;
                tags.value.push(newTag);
            });
            return data;
        }
    }

    async function deleteTags(tagIds) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        const data = await SupabaseService.deleteTags(tagIds);
        tags.value = tags.value.filter((tag) => !tagIds.includes(tag.id));
        await trackTagStore.checkInit();
        await trackTagStore.deleteTagsFromTracks(tagIds);
        return data;
    }

    return { BULK_TAG_TYPES, BULK_TAG_ACTIONS, AUTO_TAG_TYPES, tags, sortedTags, getParentTagOptions, init, checkInit, upsertTag, insertTags, deleteTags };
});
