<script setup>
import { useLayout } from '@/layout/composables/layout';
import router from '@/router';
import { SupabaseService } from '@/service/SupabaseService';
import { useDiscord } from '@/util/discord';
import { useAppToast } from '@/util/toast';
import Textarea from 'primevue/textarea';
import { ref } from 'vue';

const { onMenuToggle } = useLayout();

const session = JSON.parse(sessionStorage.session);
const toast = useAppToast();
const discord = useDiscord();
const feedbackPopover = ref();
const feedbackComment = ref('');

const toggleFeedbackPopover = (event) => {
    feedbackPopover.value.toggle(event);
};

const submitFeedback = async () => {
    feedbackPopover.value.hide();
    const data = await SupabaseService.saveFeedback(feedbackComment.value);
    if (data) {
        discord.postMessage(discord.CHANNELS.FEEDBACK, session.user.id + ': ' + feedbackComment.value);
        toast.success('Feedback submitted. Thank you! 🙏');
        feedbackComment.value = '';
    }
};
</script>

<template>
    <div class="layout-topbar grid grid-cols-3">
        <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
            <i class="pi pi-bars"></i>
        </button>
        <div class="layout-topbar-logo-container justify-center justify-self-center">
            <router-link to="/dashboard" class="layout-topbar-logo">
                <img src="/images/logo.svg" class="w-12 mr-1" alt="TagATrack logo" />
                <span>TagATrack</span>
            </router-link>
        </div>
        <div class="layout-topbar-actions">
            <button
                class="layout-topbar-menu-button layout-topbar-action"
                v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
            >
                <i class="pi pi-ellipsis-v"></i>
            </button>
            <div ref="topbarMenu" class="layout-topbar-menu hidden">
                <div class="layout-topbar-menu-content">
                    <button
                        type="button"
                        class="layout-topbar-action"
                        @click="
                            router.push('/dashboard/account');
                            $refs.topbarMenu.classList.add('hidden');
                        "
                    >
                        <i class="pi pi-user"></i>
                        <span>Account</span>
                    </button>
                    <button type="button" class="layout-topbar-action" @click="toggleFeedbackPopover">
                        <i class="pi pi-comment"></i>
                        <span>Feedback</span>
                    </button>
                    <button
                        type="button"
                        class="layout-topbar-action"
                        @click="
                            router.push('/dashboard/help');
                            $refs.topbarMenu.classList.add('hidden');
                        "
                    >
                        <i class="pi pi-question"></i>
                        <span>Help</span>
                    </button>
                    <button type="button" class="layout-topbar-action" @click="SupabaseService.signOut">
                        <i class="pi pi-sign-out"></i>
                        <span>Log out</span>
                    </button>
                </div>
            </div>
            <Popover ref="feedbackPopover">
                <div class="flex flex-col gap-4">
                    <span class="font-medium block mb-2"
                        >Share your feedback (or find us on <a href="https://discord.gg/CTAyH7TRCV" target="_blank"><u>Discord</u></a> & <a href="https://www.reddit.com/r/TagATrack/" target="_blank"><u>Reddit</u></a
                        >):</span
                    >
                    <Textarea v-model="feedbackComment" rows="5" cols="30" />
                    <Button label="Submit" severity="success" @click="submitFeedback" />
                </div>
            </Popover>
        </div>
    </div>
</template>
