import { SupabaseService } from '@/service/SupabaseService';
import { useTagStore } from '@/stores/tags';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTrackTagStore = defineStore('trackTags', () => {
    const tagStore = useTagStore();

    const trackTags = ref([]);

    async function init() {
        const trackTagsData = await SupabaseService.getTrackTagsByProviderId();
        await tagStore.checkInit();
        trackTagsData.map((trackTag) => {
            trackTag.tags = tagStore.tags.filter((tag) => trackTag.tag_ids?.includes(tag.id));
        });
        trackTags.value = trackTagsData;
    }

    async function checkInit() {
        if (!trackTags.value.length) {
            await init();
        }
    }

    async function tagTrack(track, tag, checked) {
        await SupabaseService.tagTrack(track.id, tag.id, checked);
        track.tags = checked ? [...new Set([...track.tags, tag])] : track.tags.filter((t) => t.id !== tag.id);
        const trackTag = trackTags.value.find((tt) => tt.provider_id === track.id);
        if (trackTag) {
            trackTag.tag_ids = checked ? [...new Set([...trackTag.tag_ids, tag.id])] : trackTag.tag_ids.filter((t) => t !== tag.id);
            trackTag.tags = track.tags;
        } else {
            trackTags.value.push({ provider_id: track.id, tags: track.tags, tag_ids: [tag.id] });
        }
    }

    async function tagTracks(tracks, tags, checked) {
        if (!Array.isArray(tracks)) tracks = [tracks];
        if (!Array.isArray(tags)) tags = [tags];
        await SupabaseService.tagTracks(
            tracks.map((track) => track.id),
            tags.map((tag) => tag.id),
            checked
        );
        tracks.forEach((track) => {
            track.tags = checked ? [...new Set([...track.tags, ...tags])] : track.tags.filter((t) => !tags.includes(t));
            const trackTag = trackTags.value.find((tt) => tt.provider_id === track.id);
            if (trackTag) {
                trackTag.tag_ids = checked ? [...new Set([...trackTag.tag_ids, ...tags.map((tag) => tag.id)])] : trackTag.tag_ids.filter((t) => !tags.map((tag) => tag.id).includes(t));
                trackTag.tags = track.tags;
            } else {
                trackTags.value.push({ provider_id: track.id, tags: track.tags, tag_ids: tags.map((tag) => tag.id) });
            }
        });
    }

    async function upsertTrackTags(pTrackTags, tracks) {
        await SupabaseService.upsertTrackTags(pTrackTags);
        await tagStore.checkInit();
        pTrackTags.forEach((pTrackTag) => {
            const foundTags = tagStore.tags.filter((tag) => pTrackTag.tag_id === tag.id);
            pTrackTag.tags = pTrackTag.tags ? [...pTrackTag.tags, ...foundTags] : [...foundTags];
            const track = tracks.find((track) => track.id === pTrackTag.provider_id);
            track.tags = [...new Set([...track.tags, ...pTrackTag.tags])];

            const trackTag = trackTags.value.find((tt) => tt.provider_id === track.id);
            if (trackTag) {
                trackTag.tags = track.tags;
                trackTag.tag_ids = [...new Set([...trackTag.tag_ids, ...pTrackTag.tags.map((tag) => tag.id)])];
            } else {
                // TODO: Update provider to be dynamic
                trackTags.value.push({ provider_id: track.id, provider: 'spotify', tags: track.tags, tag_ids: pTrackTag.tags.map((tag) => tag.id) });
            }
        });
    }

    async function deleteTagsFromTracks(tagIds) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        trackTags.value.forEach((trackTag) => {
            trackTag.tags = trackTag.tags.filter((tag) => !tagIds.includes(tag.id));
            trackTag.tag_ids = trackTag.tag_ids.filter((tagId) => !tagIds.includes(tagId));
        });
    }

    return { trackTags, init, checkInit, tagTrack, tagTracks, upsertTrackTags, deleteTagsFromTracks };
});
