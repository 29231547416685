import { FriendlyError } from '@/util/FriendlyError';
import { createClient } from '@supabase/supabase-js';
import { SpotifyService } from './SpotifyService';

const TAG_LIMIT = 1000;
export const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_KEY, {
    auth: {
        autoRefreshToken: true,
        flowType: 'pkce'
    }
});

export const SupabaseService = {
    PROVIDERS: { SPOTIFY: SpotifyService.SUPABASE_PROVIDER_NAME },
    DEFAULT_LIMIT: 50,

    async signInWithOAuth(providerName) {
        // TODO: Add scopes for other providers
        const scopes = providerName === SpotifyService.SUPABASE_PROVIDER_NAME ? SpotifyService.SUPABASE_SCOPES : '';
        const { error } = await supabase.auth.signInWithOAuth({
            provider: providerName,
            options: {
                scopes: scopes.join(', ')
            }
        });
        if (error) throw error;
    },

    async refreshSession() {
        console.log('Refreshing session');
        const { data, error } = await supabase.auth.refreshSession();
        if (error) throw error;
        sessionStorage.setItem('session', JSON.stringify(data.session));
    },

    async signOut() {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
    },

    async getTags() {
        const { data, error } = await supabase.from('tags').select('id, name, parent_id').order('name').limit(TAG_LIMIT);
        if (error) throw error;
        return data;
    },

    async getTrackTagsByProviderId() {
        const { data, error } = await supabase.from('track_tags_view_by_provider_id').select('provider_id, tag_ids');
        if (error) throw error;
        return data;
    },

    async getTrackIdsByTagIds(tagIds, offset, limit) {
        if (!Array.isArray(tagIds)) tagIds = [tagIds];
        offset = offset || 0;
        limit = limit ? Math.max(limit, this.DEFAULT_LIMIT) : this.DEFAULT_LIMIT;
        const { data, error } = await supabase.rpc('get_provider_ids_by_tag_ids', { tag_ids: tagIds, offset_val: offset, limit_val: limit });
        if (error) {
            error.tagIds = tagIds;
            throw error;
        }
        if (data === null || data?.length === 0) return [];
        return data[0];
    },

    async upsertTag(tagId, tagName, parentId) {
        const { data, error } = await supabase
            .from('tags')
            .upsert(
                {
                    id: tagId,
                    name: tagName,
                    parent_id: parentId
                },
                { onConflict: 'id' }
            )
            .select('id, name, parent_id')
            .order('name');
        if (error) {
            error.tagId = tagId;
            error.tagName = tagName;
            error.parentId = parentId;
            if (error.code === '23505') {
                const message = 'Tag already exists: ' + tagName;
                throw new FriendlyError(message, error);
            } else {
                throw error;
            }
        }
        return data;
    },

    async insertTags(tagNames, parentId) {
        const tagData = tagNames.map((tagName) => ({ name: tagName, parent_id: parentId }));
        const { data, error } = await supabase.from('tags').insert(tagData).select('id, name, parent_id').order('name');
        if (error) {
            error.tagNames = tagNames;
            error.parentId = parentId;
            throw error;
        }
        return data;
    },

    async deleteTags(tagIds) {
        const { data, error } = await supabase.from('tags').delete().in('id', tagIds).select('id, name, parent_id').order('name');
        if (error) throw error;
        return data;
    },

    async tagTrack(trackId, tagId, added) {
        if (!added) {
            const { data, error } = await supabase.from('track_tags').delete().eq('provider_id', trackId).eq('tag_id', tagId);
            if (error) {
                error.trackId = trackId;
                error.tagId = tagId;
                error.added = added;
                throw error;
            }
            return data;
        } else {
            const { data, error } = await supabase.from('track_tags').insert({
                provider_id: trackId,
                provider: this.PROVIDERS.SPOTIFY,
                tag_id: tagId
            });
            if (error) {
                error.trackId = trackId;
                error.tagId = tagId;
                error.added = added;
                throw error;
            }
            return data;
        }
    },

    async tagTracks(trackIds, tagIds, added) {
        if (!added) {
            const { data, error } = await supabase.from('track_tags').delete().in('provider_id', trackIds).in('tag_id', tagIds);
            if (error) {
                error.trackIds = trackIds;
                error.tagIds = tagIds;
                error.added = added;
                throw error;
            }
            return data;
        } else {
            const tagTrackData = trackIds.map((trackId) => tagIds.map((tagId) => ({ provider_id: trackId, provider: this.PROVIDERS.SPOTIFY, tag_id: tagId }))).flat();
            const { data, error } = await supabase.from('track_tags').upsert(tagTrackData, { ignoreDuplicates: true, onConflict: 'user_id, provider_id, tag_id' });
            if (error) {
                error.trackIds = trackIds;
                error.tagIds = tagIds;
                error.added = added;
                throw error;
            }
            return data;
        }
    },

    async upsertTrackTags(trackTags) {
        const { data, error } = await supabase.from('track_tags').upsert(trackTags, { ignoreDuplicates: true, onConflict: 'user_id, provider_id, tag_id' });
        if (error) {
            error.trackTags = JSON.stringify(trackTags);
            throw error;
        }
        return data;
    },

    async saveFeedback(comment) {
        const { data, error } = await supabase.from('feedback').insert({ comment: comment }).select();
        if (error) throw error;
        return data;
    },

    async deleteAccount() {
        const { data, error } = await supabase.functions.invoke('delete-user');
        if (error) throw error;
        return data;
    }
};
